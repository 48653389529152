.App {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: center;
  z-index: 100;
}

/* Style the background: 100% width and height to cover the entire window */
.background {
  position: fixed;
  left: 50%;
  top: 50%;
  /* min-width: 100%; */
  min-height: 100%;
  transform: translate(-50%, -50%);
  z-index: -2;
  transition: opacity 1s linear 2s;
}

/* Style the video: 100% width and height to cover the entire window */
.video {
  position: fixed;
  left: 50%;
  top: 50%;
  /* min-width: 100%; */
  min-height: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: opacity 1s linear 2s;
}

.logo {
  color: #fff;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.33));
  position: fixed;
  left: 50%;
  top: calc(50% - 100px);
  width: 80%;
  max-width: 550px;
  transform: translate(-50%, -50%);
  transition: opacity 1s linear 2s;
}

.icons {
  position: fixed;
  left: 50%;
  top: calc(50% + 100px);
  width: 80%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  transition: opacity 1s linear 2s;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.33));
}

.icons svg {
  color: #f5f5f5;
  height: 50px;
  width: 50px;
  margin: 25px;
  /* -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.33));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.33));  */
}

@keyframes wobble {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}

.icons svg:hover {
  animation: wobble 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  transform-origin: center;
}

.refresh {
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 50px;
  width: 50px;
  transition: transform 0.7s ease-in-out;
}

.refresh:hover {
  transform: rotate(360deg);
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.show {
  opacity: 1;
}
